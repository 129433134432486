import React, { useState, useEffect, useRef } from "react";
import Index from "../../Index";
import PageIndex from "../../PagesIndex";
import FlipCountdown from "@rumess/react-flip-countdown";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import abi from "../../Connectivity/Abis/plutope.json";
import tokenAbi from "../../Connectivity/Abis/usdt.json";
import { ethers } from "ethers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CustomLoader.css";
import { styled } from "@mui/system";
import { useParams } from "react-router-dom";
import Button from "../button/Button";
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import "./Swapmodal.css";
import { useNavigate } from "react-router-dom";
import Clock from "../counter/Counter";
import PagesIndex from "../../PagesIndex";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CustomButton = styled(Index.Button)({
  backgroundColor: "#fff",
  color: "black",
  borderRadius: "20px",
  textTransform: "none",
  padding: "8px 16px",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
});

const IconContainer = styled("div")({
  backgroundColor: "#ffcc00",
  borderRadius: "50%",
  padding: "5px",
  marginLeft: "8px",
});

function NewHero() {
  const [open1, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };
  const contractAddress = "0x6fD1cf06c0667D7C71EE2Dc21e84E278737b2a2F";
  const divRef = useRef(null);
  const referreraddress = useParams();
  const { open } = useWeb3Modal();
  const [tokensData, setTokensData] = useState({
    currentPhasePrice: 0,
    nextPhasePrice: 0,
    totalTokensSold: 0,
    totalTokensSoldInPhases: 0,
    privateSalePhaseName: "",
    activePhaseIndex: "0",
    isPrivateSaleActive: false,
    remainingTokens: 0,
  });
  const [selectedToken, setSelectedToken] = React.useState("USDT");
  const [getToken, setGetToken] = useState(0);
  const [tokenInBNB, setTokenInBNB] = React.useState("");
  const { walletProvider } = useWeb3ModalProvider();
  const { isConnected, address } = useWeb3ModalAccount();
  const [progressValue, setProgressValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [allowanceToken, setAllowanceToken] = useState(0);
  const [approvalLoader, setApprovalLoader] = useState(false);
  const [buyLoader, setBuyLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const targetDate = "2024-10-19T20:17:37";
  const handleDivClick = () => {
    document.body.classList.add("div-clicked");
  };

  const GenerateReferralLink = async () => {
    try {
      if (address) {
        const link = `https://presale.plutope.io/${address}`;
        navigator.clipboard.writeText(link);
        toast.success("Referral link copied to clipboard");
        // alert("Referral link copied to clipboard");
      } else {
        alert("Please Connect Wallet First");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const changeFormat = (number) => {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      style: "decimal",
    }).format(number);
    return formattedNumber;
  };
  const allReadData = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed1.binance.org/"
    );
    const contract = new ethers.Contract(contractAddress, abi, provider);
    try {
      if (isConnected) {
        const checkRefferel = await contract.getReferrer(address);
        if (checkRefferel != "0x0000000000000000000000000000000000000000") {
          navigate("/");
        }
      }
      const getPhaseDetails = await contract.getPhasePricesAndTokens();

      setTokensData({
        currentPhasePrice: getPhaseDetails[0].toString() / 10000,
        nextPhasePrice: getPhaseDetails[1].toString() / 10000,

        privateSalePhaseName: getPhaseDetails[4],
        activePhaseIndex: getPhaseDetails[5].toString(),
        isPrivateSaleActive: getPhaseDetails[6],
        totalTokensSold: changeFormat(
          (getPhaseDetails[2].toString() / 10 ** 18).toFixed()
        ),
        totalTokensSoldInPhases: changeFormat(
          getPhaseDetails[3].toString() / 10 ** 18
        ),
        remainingTokens:
          getPhaseDetails[3].toString() / 10 ** 18 -
          (getPhaseDetails[2].toString() / 10 ** 18).toFixed(),
      });

      const value =
        ((getPhaseDetails[2].toString() / 10 ** 18) * 100) /
        (getPhaseDetails[3].toString() / 10 ** 18);
      setProgressValue(value);
      handleOpen();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleChangeInput = (e) => {
    const value = e.target.value;
    setGetToken(value);
    const provider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed1.binance.org/"
    );
    const contract = new ethers.Contract(contractAddress, abi, provider);
    try {
      const weiValue = ethers.utils.parseEther(value.toString());
      contract
        .getBNBEquivalentUSDT(weiValue)
        .then((result) => {
          const getTokenPrice =
            result / tokensData.currentPhasePrice / 10 ** 18;
          setTokenInBNB(getTokenPrice);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const buyTokens = async () => {
    setIsLoading(true);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const balance = await signer.getBalance();
    const tokenContract = new ethers.Contract(
      "0x55d398326f99059fF775485246999027B3197955",
      tokenAbi,
      signer
    );
    const contract = new ethers.Contract(contractAddress, abi, signer);
    try {
      const weiValue = ethers.utils.parseEther(getToken.toString());

      const referrer =
        referreraddress.referraladdress ||
        "0x0000000000000000000000000000000000000000";
      const gasPrice = await provider.getGasPrice();
      let estimatedGasLimit, gasCost, totalCost;

      if (selectedToken === "USDT") {
        const USDTBalance = await tokenContract.balanceOf(address);
        if (Number(USDTBalance.toString()) < Number(weiValue.toString())) {
          console.log(USDTBalance.toString(), "USDTBalance");
          throw new Error("You have insufficient USDT balance in you wallet");
        }

        const checkAllowence = await tokenContract.allowance(
          address,
          contractAddress
        );
        setAllowanceToken(checkAllowence.toString());
        if (Number(checkAllowence.toString()) < Number(weiValue.toString())) {
          setApprovalLoader(true);
          const approveToken = await tokenContract.approve(
            contractAddress,
            weiValue
          );
          await approveToken.wait();
          setApprovalLoader(false);
        }
        estimatedGasLimit = await contract.estimateGas.buyPrivateSaleToken(
          "0",
          weiValue,
          referrer
        );
        gasCost = gasPrice.mul(estimatedGasLimit);
        if (balance.lt(gasCost)) {
          throw new Error("User Insufficient Balance for gas fees");
        }
        if (referreraddress.referraladdress !== undefined) {
          setBuyLoader(true);
          const tx = await contract.buyPrivateSaleToken(
            "0",
            weiValue,
            referreraddress.referraladdress
          );
          await tx.wait();
          setBuyLoader(false);
        } else {
          setBuyLoader(true);
          const tx = await contract.buyPrivateSaleToken(
            "0",
            weiValue,
            "0x0000000000000000000000000000000000000000"
          );
          await tx.wait();
          setBuyLoader(false);
        }

        toast.success("Transaction is successfully completed");
        allReadData();
      } else {
        setIsLoading(true);
        if (balance.lt(weiValue)) {
          throw new Error("You have insufficient BNB balance in you wallet");
        }
        estimatedGasLimit = await contract.estimateGas.buyPrivateSaleToken(
          "1",
          weiValue,
          referrer,
          {
            value: weiValue,
          }
        );
        gasCost = gasPrice.mul(estimatedGasLimit);

        console.log(
          "estimatedGasLimit : ",
          estimatedGasLimit.toString(),
          "gasCost :",
          gasCost.toString()
         );
        totalCost = weiValue.add(gasCost);

        if (referreraddress.referraladdress !== undefined) {
          setBuyLoader(true);
          const tx = await contract.buyPrivateSaleToken(
            "1",
            weiValue,
            referreraddress.referraladdress,
            {
              value: weiValue,
            }
          );
          await tx.wait();
          setBuyLoader(false);
        } else {
          setBuyLoader(true);
          const tx = await contract.buyPrivateSaleToken(
            "1",
            weiValue,
            "0x0000000000000000000000000000000000000000",
            {
              value: weiValue,
            }
          );
          await tx.wait();
          setBuyLoader(false);
        }
        toast.success("Transaction is successfully completed");
        allReadData();
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || error?.error?.data?.message || error?.message;
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const getUrl = () => {
    if (selectedToken === "USDT") {
      return `https://buy.onramper.com?apiKey=pk_prod_01HW2JWR5H94S217WD3M8ERQTQ&mode=buy&onlyCryptos=bnb_bsc,usdt_bsc&wallets=usdt_bsc:${address}&defaultCrypto=usdt_bsc`;
    } else if (selectedToken === "BNB") {
      return `https://buy.onramper.com?apiKey=pk_prod_01HW2JWR5H94S217WD3M8ERQTQ&mode=buy&onlyCryptos=bnb_bsc,usdt_bsc&wallets=bnb_bsc:${address}&defaultCrypto=bnb_bsc`;
    }
    return "#";
  };

  useEffect(() => {
    allReadData();
    // if (isConnected) {
    //   AddReferrer();
    // }
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        document.body.classList.remove("div-clicked");
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isConnected, address]);

  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timerId); // Cleanup the interval on component unmount
  }, [targetDate]);

  const [crypto, setCrypto] = React.useState("");

  const handleChange = (event) => {
    setCrypto(event.target.value);
  };

  const [isShowFirstSection, setIsShowFirstSection] = useState(false);

  const handleScroll = () => {
    if (window.innerWidth < 2000 && window.scrollY <= 50) {
      setIsShowFirstSection(true);
    } else {
      setIsShowFirstSection(false);
    }
  };

  const handlesubmitforConnect = async () => {
    await open();
  };

  useEffect(() => {
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  return (
    <>
      <Index.Box className="new-hero-sec sec-padding">
        <Index.Box className="container">
          <Index.Box className="hero-main-rapper py-80 cus-height">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 3, lg: 2 }}
              className="login-form"
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 4",
                }}
                className="hero-sec-col"
              >
                <Index.Box className="hero-text-area">
                  <Index.Typography component="h1" className="text">
                    BUY <span xs={{ fontWeight: "bolder" }}>PLT</span> with
                    BNB/USDT
                  </Index.Typography>
                  <Index.Typography component="p">
                    <b>PLT</b> is the native-token of PlutoPe and is a BEP-20
                    token. During the pre-sale, you can buy <b>PLT</b> with BNB
                    or USDT on Binance Smart Chain.
                  </Index.Typography>
                  <Index.Box className="btn-wrape">
                    <Index.Link
                      className="header-nav-link"
                      to="https://plutope.app/api/images/PLT_Token_Whitepaper_V1.4_Updated.pdf"
                      target="_blank"
                    >
                      <PageIndex.Button primary>Litepaper</PageIndex.Button>
                    </Index.Link>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 4",
                }}
                className="hero-sec-col"
              >
                <Index.Box className="pre-seed-sec glass-bg">
                  <Index.Typography component="h4">
                    {tokensData.privateSalePhaseName}
                    <Index.Box className="label-wrape">
                      <PageIndex.OpenLabel />
                    </Index.Box>
                  </Index.Typography>
                  <Index.Box className="transfer-wrape">
                    <Index.Box className="text-wrape">
                      <Index.Typography component="h6">
                        <Index.Box className="icon">
                          <PageIndex.Svg.BNB />
                        </Index.Box>{" "}
                        Buy with BSC
                      </Index.Typography>

                      <Index.Typography component="h6">
                        {/* 1 <b>PLT</b> = $0.033 */}1 <b>PLT</b> = $
                        {tokensData.currentPhasePrice}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="gray-pad">
                      <Index.Typography component="p">You Pay</Index.Typography>
                      <Index.Box className="inner-pad">
                        <Index.Box className="input-wrape">
                          <Index.Box
                            className="input-pad"
                            ref={divRef}
                            onClick={handleDivClick}
                          >
                            <Index.TextField
                              placeholder="Enter amount"
                              type="number"
                              onChange={handleChangeInput}
                            ></Index.TextField>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="input-wrape">
                          <Index.Box className="input-pad">
                            <Index.FormControl fullWidth>
                              <Index.Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedToken}
                                onChange={(e) =>
                                  setSelectedToken(e.target.value)
                                }
                                label="crypto"
                              >
                                <Index.MenuItem value={"USDT"}>
                                  <Index.Box className="dd-icon">
                                    <PageIndex.Svg.TUSDT />
                                  </Index.Box>
                                  USDT
                                </Index.MenuItem>
                                <Index.MenuItem value={"BNB"}>
                                  <Index.Box className="dd-icon">
                                    <PageIndex.Svg.BNB />
                                  </Index.Box>
                                  BNB
                                </Index.MenuItem>
                              </Index.Select>
                            </Index.FormControl>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Typography component="p" className="base-price">
                        {tokensData.privateSalePhaseName} Price{" "}
                        <Index.Typography component="b">
                          ${tokensData.currentPhasePrice}
                        </Index.Typography>
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="gray-pad">
                      <Index.Typography component="p">
                        You Receive
                      </Index.Typography>
                      <Index.Typography component="h1">
                        <Index.Box className="p-currency">
                          <PageIndex.Svg.logoSymbol />
                        </Index.Box>
                        {selectedToken === "USDT"
                          ? getToken === 0
                            ? 0
                            : Number(
                                getToken / tokensData.currentPhasePrice
                              ).toFixed(2)
                          : getToken === 0
                          ? 0
                          : Number(tokenInBNB).toFixed(2)}
                      </Index.Typography>
                    </Index.Box>
                    {isConnected ? (
                      <Index.Box className="copy-btn-sec">
                        <Index.Typography component="h1">
                          <CustomButton
                            onClick={GenerateReferralLink}
                            disableRipple
                            className="referral-copy-btn"
                          >
                            Copy Your Referral Link
                            <IconContainer className="copy-ic-bg">
                              <Index.ContentCopyIcon />
                            </IconContainer>
                          </CustomButton>
                        </Index.Typography>
                      </Index.Box>
                    ) : (
                      <></>
                    )}

                    <Index.Box className="progress-wrape">
                      <Index.Typography component="p">
                        Until next price{" "}
                        <Index.Typography component="b">
                          ${tokensData.nextPhasePrice}
                        </Index.Typography>
                      </Index.Typography>
                      <Index.Box className="progress-pad">
                        <Index.LinearProgress
                          variant="determinate"
                          value={progressValue}
                        />
                      </Index.Box>
                      <Index.Typography component="p">
                        <span>{tokensData.totalTokensSold} PLT</span> /
                        <span>{tokensData.totalTokensSoldInPhases} PLT</span>
                      </Index.Typography>
                    </Index.Box>
                    <Typography>{errorMessage}</Typography>
                    <Index.Box className="footer-action">
                      {isConnected ? (
                        <Index.Box className="Btn-main-box">
                          {isLoading ? (
                            <Index.Button
                              className="submit-btn wallet-connetct submit-loader-btn"
                              sx={{ width: "158.391px" }}
                            >
                              <span class="loader"></span>
                            </Index.Button>
                          ) : (
                            <Index.Button
                              onClick={buyTokens}
                              className="submit-btn wallet-connetct"
                            >
                              <Index.Box component="span" className="icon">
                                <PageIndex.Svg.ConnectWallet />
                              </Index.Box>{" "}
                              BUY NOW
                            </Index.Button>
                          )}

                          <Index.Link to={getUrl()} target="_blank">
                            <Index.Button className="submit-btn wallet-connetct">
                              <Index.Box component="span" className="icon">
                                <PageIndex.Svg.ConnectWallet />
                              </Index.Box>{" "}
                              TOP UP
                            </Index.Button>
                          </Index.Link>
                        </Index.Box>
                      ) : (
                        <Index.Button
                          className="submit-btn wallet-connetct"
                          onClick={handlesubmitforConnect}
                        >
                          <Index.Box component="span" className="icon">
                            <PageIndex.Svg.ConnectWallet />
                          </Index.Box>{" "}
                          Connect Wallet
                        </Index.Button>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="audit-img-wrape cred-shields-sec">
                    <Index.Box className="audit-img-header">
                      <Index.Typography component="p" className="audit-text">
                        Secured by
                      </Index.Typography>
                    </Index.Box>
                    <img
                      src={PagesIndex.Png.audit}
                      alt="audit"
                      className="audit-img"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 4",
                }}
                className="hero-sec-col"
              >
                <div className="countdown-wrapper glass-bg">
                  <Index.Typography component="h5" className="box-head-text">
                    ENDING SOON!
                  </Index.Typography>
                  <Index.Typography component="p">
                    Grab your hands on <b>PLT</b> now, the clock is ticking…
                  </Index.Typography>

                  <Index.Box className="countdown-pad">
                    {/* <FlipCountdown
                      hideYear
                      endAt={"2024-11-18"} // Date/Time
                      titlePosition="bottom"
                      theme="dark"
                    /> */}
                    <Clock deadline="2024-10-19T20:17:37" />
                  </Index.Box>
                </div>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {/* <Button variant="contained" onClick={handleOpen}>
            Open Swap Modal
          </Button> */}
        </Index.Box>
      </Index.Box>
      {/* <Modal open={open1} onClose={handleClose}>
        <Box className="modal-box">
          <Box className="modal-header">
            <Typography variant="h6">Buy <b>PLT</b> Tokens</Typography>
            <IconButton onClick={handleClose} className="close-button">
              <Close />
            </IconButton>
          </Box>
          <Box className="modal-content">
            <Typography variant="h5" sx={{ marginBottom: "5px" }}>
              Sell
            </Typography>
            <Typography variant="h5" sx={{ marginBottom: "20px" }}>
              {getToken}
            </Typography>
            <Typography
              variant="h5"
              className="mt-2"
              sx={{ marginBottom: "5px" }}
            >
              Buy
            </Typography>
            <Typography variant="h5" sx={{ marginBottom: "25px" }}>
              
              {selectedToken === "USDT"
                ? getToken === 0
                  ? 0
                  : Number(getToken / tokensData.currentPhasePrice).toFixed(2)
                : getToken === 0
                ? 0
                : Number(tokenInBNB).toFixed(2)}
            </Typography>
            {selectedToken === "USDT" ? (
              <List className="approval-steps mt-3">
                {allowanceToken > getToken * 10 ** 18 ? (
                  <ListItem>
                    <ListItemText primary="Already Approved" />
                  </ListItem>
                ) : (
                  <ListItem>
                    <span class="loader"></span>
                    <ListItemText primary="Approve in wallet" />
                  </ListItem>
                )}
                <ListItem>
                  <ListItemText primary="Sign message" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Confirm swap" />
                </ListItem>
              </List>
            ) : (
              <List className="approval-steps mt-3">
                <ListItem>
                  <ListItemText primary="Sign message" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Confirm swap" />
                </ListItem>
              </List>
            )}
          </Box>
        </Box>
      </Modal> */}

      <Modal
        open={open1}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="progress-modal-main"
      >
        <Box sx={style} className="progress-modal-inner">
          <Index.Box className="progress-modal-header">
            <Index.Typography component="h4" className="progress-modal-title">
              <span className="lowercase-text">50% off on </span>
              {tokensData.privateSalePhaseName}
            </Index.Typography>
            <img
              src={PagesIndex.Svg.closeIcon}
              alt="progress"
              className="close-icon"
              onClick={handleClose}
            />
          </Index.Box>

          <Index.Typography component="h5" className="box-head-text">
            ENDING SOON!
          </Index.Typography>
          <Index.Typography component="p">
            Get 50% off on token untill the phase ends
          </Index.Typography>

          <Index.Box className="modal-counter-main">
            <Index.Box className="modal-counter-flex">
              <Index.Box className="modal-counter-box">
                <Index.Typography className="modal-counter-number-text">
                  {timeLeft.days}
                </Index.Typography>
                <Index.Typography className="modal-counter-days-text">
                  Days
                </Index.Typography>
              </Index.Box>

              <Index.Box className="modal-counter-box">
                <Index.Typography className="modal-counter-number-text">
                  {timeLeft.hours}
                </Index.Typography>
                <Index.Typography className="modal-counter-days-text">
                  Hours
                </Index.Typography>
              </Index.Box>

              <Index.Box className="modal-counter-box">
                <Index.Typography className="modal-counter-number-text">
                  {timeLeft.minutes}
                </Index.Typography>
                <Index.Typography className="modal-counter-days-text">
                  Minutes
                </Index.Typography>
              </Index.Box>
              <Index.Box className="modal-counter-box">
                <Index.Typography className="modal-counter-number-text">
                  {timeLeft.seconds}
                </Index.Typography>
                <Index.Typography className="modal-counter-days-text">
                  Seconds
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Typography className="buy-remain-text">
            Remaining token :{" "}
            <b>{changeFormat(tokensData.remainingTokens)} PLT</b>
          </Index.Typography>
          <Index.Box className="buy-btn-main">
            <Index.Button className="buy-btn" onClick={handleClose}>
              Buy Now
            </Index.Button>
          </Index.Box>
        </Box>
      </Modal>

      <ToastContainer />
    </>
  );
}

export default NewHero;
